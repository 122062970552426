import {useLocation} from '@reach/router';
import {graphql, useStaticQuery} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import React from 'react';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import {PageContent} from '../components/Layout';
import Thumbnail from '../components/Thumbnail';
import {Appear, ThumbnailContainer} from '../components/atoms';
import useTheaterData from '../queries/useTheaterData';
import {NavigationContext} from '../services/navigation';
import {getYear} from '../utils/getYear';

const Container = styled(PageContent)`
  display: flex;
`;

const Theater: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const {state} = useLocation();
  const ref = React.useRef();
  const allTheaters = useTheaterData();
  const {bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        publicURL
      }
    }
  `)
  console.log(allTheaters)
  return (
    <Container ref={ref}>
      <Helmet
        title={`Theater | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: 'Theater plays for which Stavros Makronis composed the music',
          },
          {
            name: 'keywords',
            content: 'theater, score, music, Stavros Markonis',
          },
          {
            property: `og:title`,
            content: `Theater | Stavros Markonis`,
          },
          {
            property: `og:description`,
            content: 'Theater plays for which Stavros Makronis composed the music',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Theater | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content:  'Theater plays for which Stavros Makronis composed the music',
          },
        ]}
      />
      <Appear>
        <ThumbnailContainer translation={(state as any)?.enableTranslationTransition}>
          {allTheaters.map(theater => (
            <Thumbnail
            key={theater.id}
            image={getImage((theater.thumbnail as any).file)}
            title={`${theater.title}`}
            date={getYear(theater)}
            to={theater.slug}
            state={{enableTranslationTransition: true}}
          />
          ))}
        </ThumbnailContainer>
      </Appear>
    </Container>
  );
}

export default Theater;
