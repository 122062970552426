import {graphql, useStaticQuery} from 'gatsby';
import {Strapi_Theater} from '../../typegen/graphql';

const useTheaterData = () => {
  const data = useStaticQuery(graphql`
  query {
    strapi {
      theaters(sort: "date:DESC") {
        ...Theater
      }
    }
  }
`);

  return data.strapi.theaters as Strapi_Theater[];
};

export default useTheaterData;
